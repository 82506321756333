<script lang="ts" setup>
import {useSettingsStore} from "~/stores/settings";
import {useElementor, useWp} from "~/composables/Redmonkey";
import TitleBase from "~/components/Redmonkey/Base/TitleBase.vue";
import DecorSidebar from "~/components/Redmonkey/Base/DecorSidebar.vue";
import Accordion from "~/components/Redmonkey/Base/Accordion.vue";

const settingsStore = useSettingsStore();

const {
  getSettingsValue: getGeneralSettingValue
} = useWp()

const {
  getSettingsValue,
  isThatDefault
} = useElementor();

const props = defineProps({
  element: Object
})

const getTitle = () => {
  return getSettingsValue(props?.element, 'title') || 'Нас часто питають:';
}

const getItems = () => {
  if (!isThatDefault(props.element, 'use_default')) {
    return getSettingsValue(props.element, 'faqItems');
  }
  return getGeneralSettingValue(settingsStore.redmonkeySettings, 'faqItems', 'faq');
}
</script>

<template>
  <div class="faq">
    <TitleBase :title="getTitle()"/>
    <div class="faq-content flex wrap">
      <div class="faq-section">
        <div class="faq-item" v-for="(faq, index) in getItems()" :key="faq.question">
          <Accordion>
            <template #head>
              <h3>{{faq.question}}</h3>
              <div class="num">
                <span>{{`0${index+1}`}}</span>
              </div>
            </template>
            <template #body>
              <div v-html="faq.answer"></div>
            </template>
          </Accordion>
        </div>
      </div>
      <DecorSidebar/>
    </div>
  </div>
</template>

<style lang="scss">
@import "assets/css/source/components/widgets/faq";
</style>